import React from 'react';
import {SEO, MainLayout, Grid, AboutPanel} from '../components';

export default function About() {
	const dayLength = 24 * 60 * 60 * 1000;
	const startDate = new Date(2017, 0, 1);
	const dayCount = Math.floor(
		Math.abs((startDate.getTime() - new Date().getTime()) / dayLength),
	);

	return (
		<MainLayout title="about">
			<SEO title="About" />
			<h3>Justin Mills</h3>
			<br />
			<p>
				I began to explore building software for the web as a means of bringing
				my friends together, launching my first website and online forum at the
				age of 10.
			</p>
			<br />
			<p>
				Since then, I have been passionately envisioning, architecting, and
				developing dreams into applications and games.
			</p>
			<hr />
			<h4>Career Pursuits</h4>
			<br />
			<Grid>
				<AboutPanel to="https://www.halp.co">
					<h5>Halp.co - CTO & Co-Founder</h5>
					<h6>(2019 - present)</h6>
					<br />
					<p>Lead architect and full stack developer</p>
				</AboutPanel>
				<AboutPanel to="https://shopify.com">
					<h5>Shopify - Senior Web Developer & Team Lead</h5>
					<h6>(2018, 2019 - 2023)</h6>
					<br />
					<p>Web developer - platform and foundations</p>
				</AboutPanel>
			</Grid>
			<hr />
			<h4>Education</h4>
			<br />
			<Grid>
				<AboutPanel to="https://uwaterloo.ca">
					<h5>University of Waterloo</h5>
					<h6>(2014 - 2019)</h6>
					<br />
					<p>
						Honors Bachelors of Computer Science - Co-op; Software Engineering
						Option
					</p>
				</AboutPanel>
				<AboutPanel to="https://wlu.ca/">
					<h5>Wilfrid Laurier University & Laziridis School of Business </h5>
					<h6>(2014 - 2019)</h6>
					<br />
					<p>
						Honors Bachelors of Business Administration; Marketing
						Concentration; Economics Minor
					</p>
				</AboutPanel>
			</Grid>
			<hr />
			<h4>Personal</h4>
			<br />
			<Grid>
				<AboutPanel to="https://www.instagram.com/jrunsalot/">
					<h5>Running & Photography</h5>
					<h5>(2017 - now)</h5>
					<br />
					<p>
						I have been running everyday consecutively for the past {dayCount}{' '}
						days.
					</p>
					<p>
						I take photos during these runs and share them on my instagram
						@jrunsalot.
					</p>
				</AboutPanel>
			</Grid>
		</MainLayout>
	);
}
